import { ListboxOption } from "@reach/listbox";
import classNames from "classnames";
import { ButtonPrimary } from "components/Clickable/Buttons";
import { Dropdown } from "components/Dropdown/Dropdown";
import { TextAreaGroup, TextInputGroup } from "components/Input/Input";
import { writeCMSDataClientSide } from "helpers/api";
import { basicEmailPattern } from "helpers/forms";
import Script from "next/script";
import { useState } from "react";
import {
    Controller,
    FieldError,
    SubmitHandler,
    useForm,
} from "react-hook-form";

import { NewContactHardcodedFormCmsData, SuccessMessage } from ".";
import { statesAndTerritories } from "./states";

import dynamicsStyles from "./dynamics-overrides.module.scss";
import styles from "./hardcoded-forms.module.scss";

const defaultQuestionType = "Customer Service";
export const QuestionTypes = [
    defaultQuestionType,
    "Product Question",
    "Technical Question",
    "Find a Dealer",
    "Existing Dealer Requesting Customer Portal Log In",
    "Billing Question",
    "Press Inquiries",
    "Other Question",
    "Request Literature",
    "Interested in becoming an Emtek dealer",
];

interface ContactForm {
    firstName: string;
    lastName: string;
    company?: string;
    address1: string;
    address2?: string;
    city: string;
    state: string;
    postalCode: string;
    email: string;
    phone: string;
    questionType: string;
    comments: string;
}

const buildMessage = (data: ContactForm) => {
    return `CONTACT FORM
First Name: ${data.firstName}
Last Name: ${data.lastName}
Company: ${data.company ? data.company : "Not given"}
Address:
${data.address1}
${data.address2 ? data.address2 : ""}
${data.city}, ${data.state} ${data.postalCode},
Email: ${data.email},
Phone: ${data.phone},
Question Type: ${data.questionType}
Comments:
${data.comments}
`;
};

const getSendToEmail = (
    props: NewContactHardcodedFormCmsData,
    questionType: string,
) => {
    const foundContact = props.question_type_destination_emails.find(
        (contact) => contact.question_type === questionType,
    );
    if (foundContact) {
        return foundContact["destination_email"];
    } else {
        return props.default_destination_email;
    }
};
export const NewContact = (props: NewContactHardcodedFormCmsData) => {
    const statePlaceholder = "Select a state";
    const requiredErrorMessage = "This field is required.";

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<ContactForm>();

    const [formSubmittedSucessfully, setFormSubmittedSuccessfully] = useState<
        string | false
    >(false);
    const onSubmit: SubmitHandler<ContactForm> = async (data) => {
        const msgData = {
            subject: "Contact Form Submission",
            send_to: getSendToEmail(props, data.questionType),
            reply_to: data.email,
            message: buildMessage(data),
        };
        const response = await writeCMSDataClientSide(
            "POST",
            "/api/contact/",
            {},
            msgData,
        );
        if (response.ok)
            setFormSubmittedSuccessfully(
                "Thank you! We received your message.",
            );
    };

    const processError = (error: FieldError) => {
        if (error.type === "required") return requiredErrorMessage;
        if (error.type === "pattern") return error.message;
    };

    return !formSubmittedSucessfully ? (
        <form
            className={classNames({
                [styles.form]: true,
            })}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <TextInputGroup
                {...register("firstName", { required: true })}
                className={styles.sixColumns}
                label="First Name"
                type="text"
                errorMessage={errors.firstName && requiredErrorMessage}
            />
            <TextInputGroup
                {...register("lastName", { required: true })}
                className={styles.sixColumns}
                label="Last Name"
                type="text"
                errorMessage={errors.lastName && requiredErrorMessage}
            />
            <TextInputGroup
                {...register("company")}
                className={styles.twelveColumns}
                label="Company"
                required={false}
                type="text"
            />
            <TextInputGroup
                {...register("address1", { required: true })}
                className={styles.twelveColumns}
                label="Address 1"
                type="text"
                errorMessage={errors.address1 && requiredErrorMessage}
            />
            <TextInputGroup
                {...register("address2")}
                className={styles.twelveColumns}
                label="Address 2"
                required={false}
                type="text"
            />
            <TextInputGroup
                {...register("city", { required: true })}
                className={styles.fourColumns}
                label="City"
                type="text"
                errorMessage={errors.city && requiredErrorMessage}
            />
            <Controller
                name="state"
                control={control}
                rules={{ validate: (value) => value !== statePlaceholder }}
                defaultValue={statePlaceholder}
                render={({ field: { onChange, value } }) => (
                    <Dropdown
                        className={styles.fourColumns}
                        label="State/Province/Territory"
                        labelSize="small"
                        placeholder={statePlaceholder}
                        value={value}
                        onChange={onChange}
                        errorMessage={errors.state && requiredErrorMessage}
                    >
                        {statesAndTerritories.map((state) => (
                            <ListboxOption
                                key={state.abbreviation}
                                value={state.abbreviation}
                            >
                                {state.name}
                            </ListboxOption>
                        ))}
                    </Dropdown>
                )}
            />
            <TextInputGroup
                {...register("postalCode", { required: true })}
                className={styles.fourColumns}
                label="Postal Code"
                type="text"
                errorMessage={errors.postalCode && requiredErrorMessage}
            />
            <TextInputGroup
                {...register("email", {
                    required: true,
                    pattern: basicEmailPattern,
                })}
                className={styles.sixColumns}
                label="Email Address"
                type="email"
                errorMessage={errors.email && processError(errors.email)}
            />
            <TextInputGroup
                {...register("phone", {
                    required: false,
                })}
                className={styles.sixColumns}
                label="Phone"
                type="tel"
                errorMessage={errors.phone && processError(errors.phone)}
            />
            <Controller
                name="questionType"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultQuestionType}
                render={({ field: { onChange, value } }) => (
                    <Dropdown
                        className={styles.twelveColumns}
                        label="Select Question Type"
                        labelSize="small"
                        value={value}
                        onChange={onChange}
                        errorMessage={
                            errors.questionType && requiredErrorMessage
                        }
                    >
                        {QuestionTypes.map((question, index) => (
                            <ListboxOption key={index} value={question}>
                                {question}
                            </ListboxOption>
                        ))}
                    </Dropdown>
                )}
            />
            <TextAreaGroup
                {...register("comments", { required: true })}
                className={classNames(styles.twelveColumns, styles.textarea)}
                label="Questions/Comments"
                errorMessage={errors.comments && requiredErrorMessage}
            />
            <div className={styles.twelveColumns}>
                <ButtonPrimary
                    className={`al-contact-form__submit`}
                    type="submit"
                >
                    Submit
                </ButtonPrimary>
            </div>
        </form>
    ) : (
        <SuccessMessage message={formSubmittedSucessfully} />
    );
};

export const Contact = () => {
    return (
        <>
            <div
                className={dynamicsStyles.root}
                data-form-block-id="e0308e99-5eac-e911-a95d-000d3a3b9825"
            ></div>{" "}
            <Script src="https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.84.2007" />{" "}
            <div id="dAckHA4TnsyLKMPRraI764TmsUIsOKEs4Hohjn0BnKus"></div>
            <Script src="https://mktdplp102cdn.azureedge.net/public/latest/js/ws-tracking.js?v=1.84.2007" />
            <div
                className="d365-mkt-config"
                style={{ display: "none" }}
                data-website-id="AckHA4TnsyLKMPRraI764TmsUIsOKEs4Hohjn0BnKus"
                data-hostname="c8be17d1e4c0457b93d143d45843c42d.svc.dynamics.com"
            ></div>
        </>
    );
};
